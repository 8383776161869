import { UserProfile } from 'api/account/models/UserProfile';
import AlertNoticesService from 'api/alertNotices/AlertNoticesService';
import { AlertNoticeTypeEnum } from 'api/alertNotices/enums/AlertNoticeTypeEnum';
import { AlertNoticeDto, SelectedAlertNoticeDto } from 'api/alertNotices/models/AlertNoticeDto';
import { CatalogSupplierSearchCriteria } from 'api/catalog/models/CatalogSupplierSearchCriteria';
import { Periodicity } from 'api/contracts/enums/Periodicity';
import { TaxVat } from 'api/contracts/enums/TaxVat';
import { UseContractDto } from 'api/contracts/useContract/models/UseContractDto';
import UseContractsService from 'api/contracts/useContract/UseContractsService';
import { GenericType } from 'api/genericTypes/enums/GenericType';
import SuppliersService from 'api/suppliers/SuppliersService';
import UsersService from 'api/users/UsersService';
import IconBack from 'assets/svg/Arrow-grey.svg';
import Button from 'common/components/button/Button';
import DateTimePickerController from 'common/components/dateTimePicker/DateTimePickerController';
import DateTimePickerRange from 'common/components/dateTimePickerRange/DateTimePickerRange';
import FileSelector from 'common/components/fileSelector/FileSelector';
import FormItem from 'common/components/formItem/FormItem';
import InputController from 'common/components/input/InputController';
import InputError from 'common/components/inputError/InputError';
import InputGroup from 'common/components/inputGroup/InputGroup';
import InputGroupController from 'common/components/inputGroup/InputGroupController';
import Label from 'common/components/label/Label';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import SelectController from 'common/components/select/SelectController';
import CustomFile from 'common/models/CustomFile';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import Utils from 'common/services/Utils';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { DATE_FORMAT_DEFAUT, DEFAULT_INPUT_RULES, DEFAULT_INPUT_RULES_WITH_REQUIRED, DEFAULT_LANGUAGE, LOGGER_LOG_TYPE, removeAccents, STORAGE } from 'Config';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaCalendarAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Reducers } from 'store/types';
import styles from './UseContractScreen.module.scss';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { ReactComponent as TrashIcon } from 'assets/svg/trash_no_color.svg';
import ReactTooltip from 'react-tooltip';
import { VehicleDto } from 'api/vehicles/models/VehicleDto';
import DateTimePickerControllerNew from 'common/components/dateTimePicker/DateTimePickerControllerNew';
import Storage from 'common/services/Storage';
import InputInfoWarning from 'common/components/inputInfoWarning/InputInfoWarning';
import TrackingService from 'api/tracking/TrackingService';
import { TrackingProvider } from 'api/tracking/enums/TrackingProvider';

export type Props = {
    itemId?: string;
    startDateEdit?: Date;
    endDateEdit?: Date;
    isDetails: boolean;
    editable: boolean;
    onBackList: () => void;
    onItemDetails: (state: boolean) => void;
    onSubmitted: () => void;
    vehicle?: VehicleDto
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement>;

enum FileUseContractType {
    GRAY_CARD = 'GRAY_CARD',
    CONTRACT_OR_PROOF_PURCHASE = 'CONTRACT_OR_PROOF_PURCHASE'
}

const UseContractScreen = ({ itemId, startDateEdit, endDateEdit, isDetails, vehicle, onBackList, onItemDetails, onSubmitted, ...props }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { vehicleId } = useParams<{ vehicleId: string }>();
    const [itemToRemove, setItemToRemove] = useState<UseContractDto>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [useContract, setUseContract] = useState<UseContractDto | null>(null);
    const locale = Storage.get(STORAGE.CURRENT_LOCALE) || DEFAULT_LANGUAGE;

    const [useContractTypeOptions, setUseContractTypeOptions] = useState<SelectValueLabel[]>([]);
    const [useContractTypePurchase, setUseContractTypePurchase] = useState<SelectValueLabel>();
    const [supplierOptions, setSupplierOptions] = useState<SelectValueLabel[]>([]);
    const [selectedStampDutyIncluded, setSelectedStampDutyIncluded] = useState<boolean>(false);
    const [selectedMaintenanceContractIncluded, setSelectedMaintenanceContractIncluded] = useState<boolean>(false);
    const [selectedInsuranceIncluded, setSelectedInsuranceIncluded] = useState<boolean>(false);

    const [contractTypesResult, setContractTypesResult] = useState<SelectValueLabel[]>([]);

    const [savedFilesGrayCard, setSavedFilesGrayCard] = useState<CustomFile[]>([]);
    const [savedFilesContractOrProofPurchase, setSavedFilesContractOrProofPurchase] = useState<CustomFile[]>([]);
    const [alertNoticeOptions, setAlertNoticeOptions] = useState<SelectValueLabel[]>([]);
    const [showAlertNotice, setShowAlertNotice] = useState<boolean>(false);
    const [showRemoveAlertNotice, setShowRemoveAlertNotice] = useState<boolean>(false);
    const [alertNoticeToRemoveIndex, setAlertNoticeToRemoveIndex] = useState<number | null>(null);
    const [alertNoticeIdsToRemove, setAlertNoticeIdsToRemove] = useState<string[]>([]);
    const [currentKmsGps, setCurrentKmsGps] = useState<number | null>(null);

    const periodicityOptions: SelectValueLabel[] = Object.keys(Periodicity).map(key => ({
        value: key,
        label: t('common.periodicities.' + key as any)
    }));

    const taxVatOptions: SelectValueLabel[] = Object.keys(TaxVat).map(key => ({
        value: key,
        label: t('common.tax_vat.' + key as any),
        fullLabel: t('common.tax_vat.FULL_' + key as any),
    }));

    const form = useForm<UseContractDto>({ shouldUnregister: false });
    const { handleSubmit, setValue, getValues, watch, errors, reset } = form;

    const alertNotices = useFieldArray<SelectedAlertNoticeDto>({ control: form.control, name: 'alertNotices', keyName: 'formId' as any });
    const [inputKmsBelowCurrentVehicleKms, setInputKmsBelowCurrentVehicleKms] = useState<boolean>(false);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasContractsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_CONTRACTS_WRITE']);
    const hasReadGPSPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_GPS_READ']);

    const getData = async () => {
        setValue('attachmentsToRemove', []);
        setValue('taxVat', taxVatOptions[0].value);
        if (Boolean(vehicle) && !itemId) {
            setValue('kmVehicle', vehicle?.currentKilometers);
        }
        try {
            Loading.show();

            const useContractTypesOrig = await UseContractsService.getUseContractTypes();
            const useContractTypes = useContractTypesOrig.map(x => ({ ...x, label: t(`common.use_contract_types.${x.label}` as any), key: x.label }));
            const useContractTypesOrdered = Utils.sortAlphabetically(useContractTypes);
            setUseContractTypeOptions(useContractTypesOrdered);

            setUseContractTypePurchase(useContractTypesOrdered.find(x => x.key == 'PURCHASE'));

            const suppliers = await SuppliersService.catalog({ typeName: GenericType.RENTAL } as CatalogSupplierSearchCriteria);
            setSupplierOptions(Utils.sortAlphabetically((suppliers || []).map((x: SelectValueLabel) => ({ value: x.value || '', label: x.label || '' }))));

            setContractTypesResult(await UseContractsService.getUseContractTypes());

            const alertNoticesDB: AlertNoticeDto[] = await AlertNoticesService.getAll();
            buildAndSetAlertNoticeOptions(alertNoticesDB);

            if (itemId) {
                const result = await UseContractsService.getById(itemId);

                if (result === undefined) {
                    addToast(t('common.messages.error_load_info'), { appearance: 'error' });
                    Logger.error(LOGGER_LOG_TYPE.USE_CONTRACTS, `Couldn't get the use contract with id: ${itemId}`);
                    onBackList();
                }

                if (result.returnDate) {
                    result.returnDate = new Date(result.returnDate);
                }

                setValue('taxVat', taxVatOptions.find(x => x.value == result.taxVat)?.value);
                reset(result);
                setSelectedStampDutyIncluded(result.stampDutyIncluded);
                setSelectedMaintenanceContractIncluded(result.maintenanceContractIncluded);
                setSelectedInsuranceIncluded(result.insuranceIncluded);

                setValuesFilesGrayCard(result.attachments.filter(x => x.fileType == FileUseContractType.GRAY_CARD));
                setValuesFilesContractOrProofPurchase(result.attachments.filter(x => x.fileType == FileUseContractType.CONTRACT_OR_PROOF_PURCHASE));

                result.startDate = moment(result.startDate).toDate();
                result.endDate = result.endDate != undefined ? moment(result.endDate).toDate() : undefined;
                setUseContract(result);

                compareKms();
            }
            setShowAlertNotice(true);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.USE_CONTRACTS, `Couldn't get information to create use contract aa`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const getGpsKms = async () => {
        if (vehicle && vehicle.id && vehicle.companyTrackingProvider && vehicle?.companyTrackingProvider !== TrackingProvider.NONE && vehicle.trackingDeviceId) {
            try {
                const position = await TrackingService.getPosition(vehicle.id);
                setCurrentKmsGps(position.km ?? null);

            } catch (error) {
                Logger.error(LOGGER_LOG_TYPE.USE_CONTRACTS, `Couldn't get vehicle gps kms`, error);
                addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            }
        }
    }

    const buildAndSetAlertNoticeOptions = (alertNoticesArr: AlertNoticeDto[]) => {
        let mappedAlertNotices: SelectValueLabel[] = [];
        if (alertNoticesArr) {
            mappedAlertNotices = alertNoticesArr.map((x, i) => {
                let label: string = x.value + ' ';
                if (x.alertNoticeType == AlertNoticeTypeEnum.KILOMETERS) {
                    label = label + (x.value > 1 ? t(('maintenances.alert_notice_type.PLURALIZED_' + AlertNoticeTypeEnum.KILOMETERS) as any) : t(('maintenances.alert_notice_type.' + AlertNoticeTypeEnum.KILOMETERS) as any)) + ' ' + t(('maintenances.alert_notice_comparison.' + x.alertNoticeComparison) as any);
                }
                else if (x.alertNoticeType == AlertNoticeTypeEnum.TIME_PERIODS) {
                    label = label + t(('maintenances.periodicities.' + x.periodicity) as any) + ' ' + t(('maintenances.alert_notice_comparison.' + x.alertNoticeComparison) as any);
                }

                return { label, value: x.id, fullLabel: x.alertNoticeType }
            });
        }
        setAlertNoticeOptions(mappedAlertNotices);
    }


    const onSubmit = async (form: UseContractDto) => {
        try {
            form.stampDutyIncluded = selectedStampDutyIncluded;
            form.maintenanceContractIncluded = selectedMaintenanceContractIncluded;
            form.insuranceIncluded = selectedInsuranceIncluded;
            form.vehicleId = vehicleId;

            if (
                !form.startDate ||
                (selectedUseContractType != useContractTypePurchase && (
                    !form.periodicity ||
                    !form.number ||
                    !form.endDate ||
                    !form.supplierId
                ))
            ) {
                addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                return;
            }

            Loading.show();
            setShowAlertNotice(false);
            form.attachments = prepareFilesToSubmit();

            if (selectedUseContractType == useContractTypePurchase) {
                form.periodicity = undefined;
                form.endDate = undefined;
                form.returnDate = undefined;
                form.returnPlace = undefined;
                form.insuranceIncluded = false;
                form.maintenanceContractIncluded = false;
                form.stampDutyIncluded = false;
                form.alertNotices = [];
                form.alertNoticesIdsToRemove = [];
            }

            form.useContractTypeName = contractTypesResult.find(x => x.value == form.useContractTypeId)?.label ?? '';

            if (form.id) {
                await UseContractsService.update(form, form.attachments ?? []);
            } else {
                itemId = await UseContractsService.create(form, form.attachments ?? []);
            }

            getData();
            Loading.hide();
            onSubmitted();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
        } catch (error) {
            Loading.hide();

            if (error?.response?.status === 409) {
                addToast(t('common.messages.name_already_exists'), { appearance: 'warning' });
                return;
            }
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.USE_CONTRACTS, `Couldn't update the use contract with id: ${form.id}`, error);
            arrangeFilesAfterSubmit();
        }
    }

    const addAlertNotice = () => {
        alertNotices.append({
            id: null
        });
    }

    const renderAlertNotice = (index: number, an: any) => {
        return (
            <SelectController
                form={form}
                name={`alertNotices[${index}].id`}
                options={alertNoticeOptions}
                placeholder={t('maintenances.alert')}
                rules={{ required: true }}
                isDisabled={(isDetails)}
                filterOption={(itemFilter: any, input: any) => input ? removeAccents(itemFilter.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
            />);
    }


    const removeAlertNotice = () => {
        if (alertNoticeToRemoveIndex === null) return;

        if (alertNotices.fields[alertNoticeToRemoveIndex]?.id) {
            setAlertNoticeIdsToRemove([...alertNoticeIdsToRemove, alertNotices.fields[alertNoticeToRemoveIndex].id!]);
        }

        alertNotices.remove(alertNoticeToRemoveIndex);

        setShowRemoveAlertNotice(false);
    }

    const setRemoveAlertNotice = (index: number) => {
        setShowRemoveAlertNotice(true);
        setAlertNoticeToRemoveIndex(index);
    }



    const onSelectStampDutyIncluded = (mode: boolean) => {
        setSelectedStampDutyIncluded(mode);
        setValue('stampDutyIncluded', selectedStampDutyIncluded);
        form.clearErrors();
        if (!mode) {
            setValue('stampDutyAmount', null);
        }
    }

    const onSelectMaintenanceContractIncluded = (mode: boolean) => {
        setSelectedMaintenanceContractIncluded(mode);
        setValue('maintenanceContractIncluded', selectedMaintenanceContractIncluded);
    }

    const onSelectInsuranceIncluded = (mode: boolean) => {
        setSelectedInsuranceIncluded(mode);
        setValue('insuranceIncluded', selectedInsuranceIncluded);
    }

    const showRemoveItemDialog = (item: UseContractDto) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await UseContractsService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                onBackList();
                onSubmitted();
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    const arrangeFilesAfterSubmit = () => {
        setValuesFilesGrayCard(savedFilesGrayCard);
        setValuesFilesContractOrProofPurchase(savedFilesContractOrProofPurchase);
    }

    const prepareFilesToSubmit = () => {
        const filesGrayCard = getValues('attachmentsGrayCard')?.filter(x => !x.id) ?? [];
        const filesContractOrProofOfPurchase = getValues('attachmentsContractOrProofPurchase')?.filter(x => !x.id) ?? [];
        setValuesFilesGrayCard(filesGrayCard);
        setValuesFilesContractOrProofPurchase(filesContractOrProofOfPurchase);
        const fullAttachments = [...filesGrayCard, ...filesContractOrProofOfPurchase];
        setValue('attachments', fullAttachments);
        return fullAttachments;
    }

    const setValuesFilesGrayCard = (files: CustomFile[]) => {
        if (files && files.length > 0) {
            files.map(x => x.containerName = FileUseContractType.GRAY_CARD);
            setValue('attachmentsGrayCard', files);
            setSavedFilesGrayCard(files?.filter(x => x.id));
        }
    }

    const setValuesFilesContractOrProofPurchase = (files: CustomFile[]) => {
        if (files && files.length > 0) {
            files.map(x => x.containerName = FileUseContractType.CONTRACT_OR_PROOF_PURCHASE);
            setValue('attachmentsContractOrProofPurchase', files);
            setSavedFilesContractOrProofPurchase(files?.filter(x => x.id));
        }
    }

    const onRemoveFile = (file: CustomFile) => {
        if (file.id) {
            const arr = [...(getValues('attachmentsToRemove') || []), file.id];
            setValue('attachmentsToRemove', arr);
        }
    }

    const getMessageToDeletion = () => {
        const labelMaintenances = useContract && Boolean(useContract?.numbersMaintenanceContracts?.length) ? t('contracts.use_contract.maintenance_contracts_associated') + useContract.numbersMaintenanceContracts.join() : undefined;
        const labelInsurance = useContract && Boolean(useContract?.numbersInsuranceContracts?.length) ? t('contracts.use_contract.insurance_contracts_associated') + useContract.numbersInsuranceContracts.join() : '';

        return (<div>
            {validateRemoveConfirmation() && <div>{t('contracts.use_contract.cant_delete_contracts_associated')}</div>}
            {labelMaintenances && <div><small>{labelMaintenances}</small></div>}
            {labelInsurance && <div><small>{labelInsurance}</small></div>}
        </div>);
    }

    const validateRemoveConfirmation = () => {
        return useContract && (Boolean(useContract?.numbersMaintenanceContracts?.length) || Boolean(useContract?.numbersInsuranceContracts?.length));
    }

    const renderButtons = () => {
        return <div className={styles.buttonsContainer}>
            <Button
                preset={'secondary'}
                type="button"
                onClick={() => {
                    if (useContract) reset(useContract);
                    onBackList();
                }}
                text={t('common.cancel')}
            />
            {isDetails && props.editable && hasContractsWritePolicy &&
                <Button
                    type="button"
                    text={t('common.remove')}
                    preset={'danger'}
                    onClick={() => showRemoveItemDialog({ id: useContract?.id, useContractTypeName: useContract?.useContractTypeName } as UseContractDto)} />
            }
            {isDetails && props.editable && hasContractsWritePolicy && <Button
                type={'button'}
                text={t('common.edit')}
                onClick={() => onItemDetails(false)}
            />}
            {!isDetails && hasContractsWritePolicy && <Button
                type={'submit'}
                text={t('common.save')}
            />}

            <QuestionYesNo
                message={!validateRemoveConfirmation() ? t('common.messages.remove_record') : undefined}
                isVisible={dialogDeleteItemIsOpen}
                onYes={() => removeItem()}
                isInformation={validateRemoveConfirmation()}
                onNo={() => setDialogDeleteItemIsOpen(false)}>
                <div>
                    {useContract && getMessageToDeletion()}
                </div>
            </QuestionYesNo>
        </div>
    }

    useEffect(() => {
        getData();
    }, []);


    useEffect(() => {
        form.clearErrors();
    }, [watch('useContractTypeId')]);

    useEffect(() => {
        if (vehicle && !isDetails) {
            // current kms from tracking
            getGpsKms();
        }
    }, [vehicle, isDetails]);

    const selectedUseContractTypeId = form.watch('useContractTypeId');
    const selectedUseContractType = useContractTypeOptions.find(x => x.value == selectedUseContractTypeId);
    const selectedPeriodicityValue = form.watch('periodicity');
    const selectedPeriodicity = periodicityOptions.find(x => x.value == selectedPeriodicityValue)?.label;

    const getTooltipToOtherContracts = (maintenance: boolean, insurance: boolean) => {
        let label = maintenance ? t('contracts.use_contract.maintenance_contracts_associated')
            : insurance ? t('contracts.use_contract.insurance_contracts_associated')
                : undefined;
        if (maintenance && Boolean(useContract) && useContract?.numbersMaintenanceContracts) {
            label = Boolean(useContract) && useContract?.numbersMaintenanceContracts && useContract?.numbersMaintenanceContracts.length > 0
                ? label + useContract.numbersMaintenanceContracts.join()
                : undefined;
        }
        if (insurance && Boolean(useContract) && useContract?.numbersInsuranceContracts) {
            label = Boolean(useContract) && useContract?.numbersInsuranceContracts && useContract?.numbersInsuranceContracts.length > 0
                ? label + useContract.numbersInsuranceContracts.join()
                : undefined;
        }
        return label;
    }

    const compareKms = () => {
        if (vehicle && (watch('kmVehicle') ?? 0) < (vehicle?.currentKilometers ?? 0)) {
            setInputKmsBelowCurrentVehicleKms(true);
            return;
        }
        setInputKmsBelowCurrentVehicleKms(false);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div {...props}>
                <Row style={{ margin: 0 }}>
                    <Col xs={12} sm={12} md={12} lg={8} xl={9}>
                        <div className={styles.content}>
                            <Row>
                                <Col xs={12}>
                                    <div className={styles.header}>
                                        <img src={IconBack} className={styles.icon} onClick={() => onBackList()} /> {t('contracts.use_contract.title')}
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={4} className={styles.col}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('contracts.use_contract.type')} {!isDetails ? '*' : ''}</Label>
                                        <SelectController
                                            form={form}
                                            isDisabled={isDetails}
                                            placeholder={t('contracts.use_contract.type')}
                                            name="useContractTypeId"
                                            options={useContractTypeOptions}
                                            rules={{ required: true }} />
                                        <InputError error={errors.useContractTypeId} />
                                    </FormItem>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={4} className={styles.col}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('contracts.use_contract.number')} {selectedUseContractType != useContractTypePurchase && !isDetails ? '*' : ''}</Label>
                                        {Boolean(watch('useContractTypeId') != useContractTypePurchase?.value) ?
                                            <InputController
                                                name="number"
                                                form={form as any}
                                                autoComplete='off'
                                                disabled={isDetails}
                                                placeholder={t('contracts.use_contract.number')}
                                                rules={{ ...DEFAULT_INPUT_RULES_WITH_REQUIRED }} />
                                            :
                                            <InputController
                                                name="number"
                                                form={form as any}
                                                autoComplete='off'
                                                disabled={isDetails}
                                                placeholder={t('contracts.use_contract.number')}
                                                rules={{ ...DEFAULT_INPUT_RULES }} />}
                                        <InputError error={errors.number} />
                                    </FormItem>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={4} className={styles.col}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('common.supplier')} {selectedUseContractType != useContractTypePurchase && !isDetails ? '*' : ''}</Label>
                                        <SelectController
                                            form={form}
                                            isDisabled={isDetails}
                                            placeholder={t('common.supplier')}
                                            name="supplierId"
                                            options={!supplierOptions.find(x => x.value == getValues('supplierId')) && Boolean(itemId) ?
                                                [...supplierOptions, { label: getValues('supplierName'), value: getValues('supplierId') }] : supplierOptions}
                                            rules={{ required: selectedUseContractType != useContractTypePurchase }}
                                            value={isDetails && getValues('supplierId') ? { label: getValues('supplierName'), value: getValues('supplierId') } : null} />
                                        <InputError error={errors.supplierId} />
                                    </FormItem>
                                </Col>

                                {selectedUseContractType != useContractTypePurchase && <Col xs={12} sm={12} md={6} lg={6} xl={4} className={styles.col}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('common.periodicity')} {!isDetails ? '*' : ''}</Label>
                                        <SelectController
                                            form={form}
                                            isDisabled={isDetails}
                                            placeholder={t('common.periodicity')}
                                            name="periodicity"
                                            options={periodicityOptions}
                                            rules={{ required: true }} />
                                        <InputError error={errors.periodicity} />
                                    </FormItem>
                                </Col>}
                                <Col xs={12} sm={12} md={12} lg={12} xl={8} className={`${styles.col} ${styles.xxl}`}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('contracts.use_contract.cost_rent', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })} {!isDetails ? '*' : ''}</Label>

                                        <InputGroupController
                                            type="number"
                                            text={t('common.euro') + (selectedUseContractType == useContractTypePurchase ? '' : selectedPeriodicity ? '/' + selectedPeriodicity : '')}
                                            placeholder={t('contracts.use_contract.cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })}
                                            name="cost"
                                            form={form}
                                            disabled={isDetails}
                                            format="money"
                                            rules={{ required: true, maxLength: 255 }} />
                                        <InputError error={errors.cost} />
                                    </FormItem>
                                </Col>
                                {selectedUseContractType != useContractTypePurchase
                                    ? <Col xs={12} sm={12} lg={6} xl={4} className={styles.col}>
                                        <FormItem>
                                            <Label className={styles.label}>{t('common.start_end_date')} {!isDetails ? '*' : ''}</Label>
                                            <div className={styles.dateRangeCustom}>
                                                <DateTimePickerRange
                                                    name="startEndDate"
                                                    dateFormat="dd/MM/yyyy"
                                                    key={`watch-${watch('startDate')}-${watch('endDate')}`}
                                                    locale={locale}
                                                    start={watch('startDate')}
                                                    end={watch('endDate')}
                                                    showYearDropdown
                                                    disabled={isDetails}
                                                    placeholderText={t('common.start_end_date')}
                                                    onChange={() => {
                                                        // do nothing.
                                                    }}
                                                    onChangeRange={(start: Date | null, end: Date | null) => {
                                                        const sd = start ? Utils.generateDate(start.getFullYear(), start.getMonth(), start.getDate()) : null;
                                                        setValue('startDate', sd);

                                                        let dateLastDayOfMonth = end;
                                                        if (end) {
                                                            dateLastDayOfMonth = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59);
                                                        }
                                                        setValue('endDate', dateLastDayOfMonth);
                                                    }}
                                                />
                                            </div>
                                            <InputError error={form.formState.isSubmitted && (!watch('startDate') || !watch('endDate')) ? { type: 'dateRange', message: t('common.errors.date_not_filled') } : undefined} />
                                        </FormItem>
                                    </Col>
                                    : <Col xs={12} sm={12} md={6} lg={6} xl={4} className={styles.col}>
                                        <FormItem>
                                            <Label className={styles.label}>{t('contracts.use_contract.purchase_date')} {!isDetails ? '*' : ''}</Label>
                                            <div className={styles.dateRangeCustom}>
                                                <DateTimePickerController
                                                    form={form}
                                                    placeholderText={t('common.date')}
                                                    onChange={(date: Date) => {
                                                        setValue('startDate', date);
                                                        setUseContract(useContract ? { ...useContract, startDate: date } : null);
                                                    }}
                                                    selected={useContract && useContract.startDate !== undefined ? moment(useContract.startDate).toDate() : null}
                                                    dateFormat={DATE_FORMAT_DEFAUT}
                                                    customInput={<InputGroup icon={<FaCalendarAlt />} />}
                                                    name="startDate"
                                                    autoComplete='off'
                                                    disabled={isDetails}
                                                    rules={{ required: true }} />
                                            </div>
                                            <InputError error={form.formState.isSubmitted && !watch('startDate') ? { type: 'dateRange', message: t('common.errors.date_not_filled') } : undefined} />
                                        </FormItem>
                                    </Col>
                                }

                                {selectedUseContractType != useContractTypePurchase && <Col xs={12} lg={6} xl={4} className={styles.col}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('contracts.maintenance_contract.km_contractual')}</Label>
                                        <InputGroupController
                                            type="number"
                                            text={t('common.kms')}
                                            placeholder={t('contracts.maintenance_contract.km_contractual')}
                                            name="kmContractual"
                                            form={form}
                                            disabled={isDetails}
                                            format="number"
                                            rules={{ required: false }} />
                                        <InputError error={errors.kmContractual} />
                                    </FormItem>
                                </Col>}
                                <Col xs={12} lg={6} xl={4} className={styles.col}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('contracts.use_contract.warranty')} {!isDetails ? '*' : ''}</Label>
                                        <div className={styles.groupInputsWarranty}>
                                            <div className={styles.groupInputWarranty}>
                                                <InputGroupController
                                                    type="number"
                                                    text={t('common.ans')}
                                                    placeholder={t('common.ans')}
                                                    name="warrantyYears"
                                                    form={form}
                                                    disabled={isDetails}
                                                    format="integer"
                                                    rules={{ required: !watch('warrantyKilometers') ? true : false, maxLength: 255, min: 0 }} />
                                                <InputError error={errors.warrantyYears} />
                                            </div>
                                            <div className={styles.groupInputSeparatorWarranty}>{t('common.or')}</div>
                                            <div className={`${styles.groupInputWarranty} ${styles.marginTopSmallScreens}`}>
                                                <InputGroupController
                                                    type="number"
                                                    text={t('common.kms')}
                                                    placeholder={t('common.kms')}
                                                    name="warrantyKilometers"
                                                    form={form}
                                                    disabled={isDetails}
                                                    format="number"
                                                    rules={{ required: !watch('warrantyYears') ? true : false, maxLength: 255, min: 0 }} />
                                                <InputError error={errors.warrantyKilometers} />
                                            </div>
                                        </div>
                                    </FormItem>
                                </Col>
                                <Col xs={12} lg={6} xl={4} className={styles.col}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('contracts.maintenance_contract.km_vehicle')}</Label>
                                        <InputGroupController
                                            type="number"
                                            text={t('common.kms')}
                                            placeholder={t('contracts.maintenance_contract.km_vehicle')}
                                            name="kmVehicle"
                                            form={form}
                                            disabled={isDetails}
                                            format="number"
                                            rules={{ required: false }}
                                            onChange={() => compareKms()} />
                                        <InputError error={errors.kmVehicle} />
                                        {vehicle && !isDetails && <InputInfoWarning isWarning={inputKmsBelowCurrentVehicleKms} message={t((inputKmsBelowCurrentVehicleKms ? 'common.inserted_kms_below_current_kms' : 'common.insert_kms_below_current_kms'), { kms: Utils.numberFormatter(vehicle?.currentKilometers ?? 0) })} />}
                                        {hasReadGPSPolicy && vehicle && !isDetails && currentKmsGps != null && <InputInfoWarning isWarning={false} message={t('location.current_km', { kms: Utils.numberFormatter(currentKmsGps ?? 0) })} />}
                                    </FormItem>
                                </Col>

                                {/* novos campos */}
                                {selectedUseContractType != useContractTypePurchase && <Col xs={12} sm={12} md={6} lg={6} xl={4} className={styles.col}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('contracts.use_contract.return_date')}</Label>
                                        <DateTimePickerControllerNew
                                            form={form}
                                            placeholderText={t('common.date')}
                                            name="returnDate"
                                            autoComplete='off'
                                            disabled={isDetails}
                                            maxDate={new Date()}
                                            rules={{ required: false }}
                                        />
                                        <InputError error={errors.returnDate} />
                                    </FormItem>
                                </Col>}

                                {selectedUseContractType != useContractTypePurchase && <Col xs={12} sm={12} md={6} lg={6} xl={4} className={styles.col}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('contracts.use_contract.return_place')}</Label>
                                        <InputController
                                            name="returnPlace"
                                            form={form as any}
                                            autoComplete='off'
                                            disabled={isDetails}
                                            placeholder={t('contracts.use_contract.return_place')}
                                            rules={{ ...DEFAULT_INPUT_RULES }} />
                                        <InputError error={errors.returnPlace} />
                                    </FormItem>
                                </Col>}

                                {selectedUseContractType != useContractTypePurchase &&
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className={styles.col} />}
                                {/* fim: novos campos */}

                                <Col xs={12} lg={6} xl={4} className={styles.col}>
                                    <FormItem>
                                        <Label className={styles.label}>{t(selectedUseContractType == useContractTypePurchase ? 'contracts.use_contract.stamp_duty' : 'contracts.use_contract.stamp_duty_included')} {!isDetails ? '*' : ''}</Label>
                                        <div className={styles.groupStampDuty}>
                                            {selectedUseContractType != useContractTypePurchase &&
                                                <div className={styles.groupSpinner}>
                                                    <div className={!isDetails ? styles.spinner : styles.spinnerDetails}>
                                                        <div onClick={() => !isDetails ? onSelectStampDutyIncluded(true) : null} style={{ width: '50%' }}>
                                                            <div className={styles.labelSpinner}>
                                                                <div className={selectedStampDutyIncluded ? styles.selected : styles.notSelected}>
                                                                    <span>{t('common.yes')}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div onClick={() => !isDetails ? onSelectStampDutyIncluded(false) : null} style={{ width: '50%' }}>
                                                            <div className={styles.labelSpinner}>
                                                                <div className={!selectedStampDutyIncluded ? styles.selected : styles.notSelected}>
                                                                    <span>{t('common.no')}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {(!selectedStampDutyIncluded || selectedUseContractType == useContractTypePurchase) && <div className={styles.groupInputStampDuty}>
                                                <InputGroupController
                                                    maxLength={25}
                                                    type="number"
                                                    text={t('common.euro')}
                                                    placeholder={t('contracts.use_contract.stamp_duty_amount')}
                                                    name="stampDutyAmount"
                                                    form={form}
                                                    disabled={isDetails}
                                                    format={'number'}
                                                    rules={{ required: { value: true, message: t('contracts.use_contract.stamp_duty_min') } }} />
                                                <InputError error={errors.stampDutyAmount} />
                                            </div>}
                                        </div>
                                    </FormItem>
                                </Col>
                                {selectedUseContractType != useContractTypePurchase && <Col xs={12} lg={6} xl={4} className={styles.col}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('contracts.use_contract.maintenance_contract_included')} {!isDetails ? '*' : ''}</Label>
                                        <div className={styles.groupSpinner} data-tip={getTooltipToOtherContracts(true, false)}>
                                            <div className={!isDetails && !Boolean(useContract?.numbersMaintenanceContracts && useContract?.numbersMaintenanceContracts.length > 0) ? styles.spinner : styles.spinnerDetails}>
                                                <div onClick={() => !isDetails && !Boolean(useContract?.numbersMaintenanceContracts && useContract?.numbersMaintenanceContracts.length > 0) ? onSelectMaintenanceContractIncluded(true) : null} style={{ width: '50%' }}>
                                                    <div className={styles.labelSpinner}>
                                                        <div className={selectedMaintenanceContractIncluded ? styles.selected : styles.notSelected}>
                                                            <span>{t('common.yes')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div onClick={() => !isDetails && !Boolean(useContract?.numbersMaintenanceContracts && useContract?.numbersMaintenanceContracts.length > 0) ? onSelectMaintenanceContractIncluded(false) : null} style={{ width: '50%' }}>
                                                    <div className={styles.labelSpinner}>
                                                        <div className={!selectedMaintenanceContractIncluded ? styles.selected : styles.notSelected}>
                                                            <span>{t('common.no')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ReactTooltip />
                                    </FormItem>
                                </Col>}
                                {selectedUseContractType != useContractTypePurchase && <Col xs={12} lg={6} xl={4} className={styles.col}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('contracts.use_contract.insurance_included')} {!isDetails ? '*' : ''}</Label>
                                        <div className={styles.groupSpinner} data-tip={getTooltipToOtherContracts(false, true)}>
                                            <div className={!isDetails && !Boolean(useContract?.numbersInsuranceContracts && useContract?.numbersInsuranceContracts.length > 0) ? styles.spinner : styles.spinnerDetails}>
                                                <div onClick={() => !isDetails && !Boolean(useContract?.numbersInsuranceContracts && useContract?.numbersInsuranceContracts.length > 0) ? onSelectInsuranceIncluded(true) : null} style={{ width: '50%' }}>
                                                    <div className={styles.labelSpinner}>
                                                        <div className={selectedInsuranceIncluded ? styles.selected : styles.notSelected}>
                                                            <span>{t('common.yes')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div onClick={() => !isDetails && !Boolean(useContract?.numbersInsuranceContracts && useContract?.numbersInsuranceContracts.length > 0) ? onSelectInsuranceIncluded(false) : null} style={{ width: '50%' }}>
                                                    <div className={styles.labelSpinner}>
                                                        <div className={!selectedInsuranceIncluded ? styles.selected : styles.notSelected}>
                                                            <span>{t('common.no')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ReactTooltip />
                                    </FormItem>
                                </Col>}


                                {selectedUseContractType != useContractTypePurchase && <Col xs={12}>
                                    <div className={styles.containerSmallButton}>
                                        <Label className={styles.label}>{t('maintenances.alert_notices_over_km_and_date_end')}</Label>
                                        {!isDetails && <ScreenHeaderButton icon={AddIcon} onClick={addAlertNotice} classNameIcon={styles.iconAdd} />}
                                    </div>

                                    {alertNotices.fields.length === 0 && <div className={styles.noInfoText}>
                                        {t('maintenances.without_alert_notices')}
                                    </div>}

                                    {!!alertNoticeOptions && !!showAlertNotice && !!alertNotices && alertNotices.fields.map((x, i) => {
                                        return (<Row key={x.formId} className={styles.rowAlerts}>
                                            <Col xs={4}>
                                                {renderAlertNotice(i, x)}
                                            </Col>
                                            {!isDetails && <Col xs={1}>
                                                <TrashIcon className={styles.removeIcon} onClick={() => setRemoveAlertNotice(i)} />
                                            </Col>}
                                        </Row>)
                                    })}
                                </Col>}
                            </Row>
                            {renderButtons()}
                        </div>

                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4} xl={3}
                        className={styles.colRightContent}>
                        <Label className={styles.subtitle}>{t('common.attachment')}</Label>
                        <div>
                            <FormItem>
                                <FileSelector
                                    isDetails={isDetails}
                                    isMulti={true}
                                    smallButtonAdd={true}
                                    initialFiles={getValues('attachmentsGrayCard')}
                                    onFilesChanged={setValuesFilesGrayCard}
                                    onRemoveFile={onRemoveFile}
                                    label={<Label className={styles.label}>{t('contracts.insurance_contract.gray_card')}</Label>}
                                    fileButtonSizes={{ sm: 12, lg: 6 }}
                                />
                            </FormItem>
                        </div>
                        <div>
                            <FormItem>
                                <FileSelector
                                    isDetails={isDetails}
                                    isMulti={true}
                                    smallButtonAdd={true}
                                    initialFiles={getValues('attachmentsContractOrProofPurchase')}
                                    onFilesChanged={setValuesFilesContractOrProofPurchase}
                                    onRemoveFile={onRemoveFile}
                                    label={<Label className={styles.label}>{t('contracts.insurance_contract.contract_or_proof_purchase')}</Label>}
                                    fileButtonSizes={{ sm: 12, lg: 6 }}
                                />
                            </FormItem>
                        </div>
                    </Col>
                </Row>
            </div>

            <QuestionYesNo
                message={t('common.messages.remove_record')}
                isVisible={showRemoveAlertNotice}
                onYes={() => removeAlertNotice()}
                onNo={() => { setShowRemoveAlertNotice(false); setAlertNoticeToRemoveIndex(null) }}
            />

        </form>
    );
}

export default UseContractScreen;
