import { UserProfile } from 'api/account/models/UserProfile';
import AlertNoticesService from 'api/alertNotices/AlertNoticesService';
import { AlertNoticeTypeEnum } from 'api/alertNotices/enums/AlertNoticeTypeEnum';
import { CatalogSupplierSearchCriteria } from 'api/catalog/models/CatalogSupplierSearchCriteria';
import { Periodicity } from 'api/contracts/enums/Periodicity';
import InsuranceContractsService from 'api/contracts/insuranceContract/InsuranceContractsService';
import UseContractsService from 'api/contracts/useContract/UseContractsService';
import { GenericType } from 'api/genericTypes/enums/GenericType';
import SuppliersService from 'api/suppliers/SuppliersService';
import UsersService from 'api/users/UsersService';
import IconBack from 'assets/svg/Arrow-grey.svg';
import Button from 'common/components/button/Button';
import DateTimePickerRange from 'common/components/dateTimePickerRange/DateTimePickerRange';
import FileSelector from 'common/components/fileSelector/FileSelector';
import FormItem from 'common/components/formItem/FormItem';
import InputController from 'common/components/input/InputController';
import InputError from 'common/components/inputError/InputError';
import InputGroupController from 'common/components/inputGroup/InputGroupController';
import Label from 'common/components/label/Label';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import Select from 'common/components/select/Select';
import SelectController from 'common/components/select/SelectController';
import CustomFile from 'common/models/CustomFile';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import Utils from 'common/services/Utils';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { DEFAULT_INPUT_RULES_WITH_REQUIRED, DEFAULT_LANGUAGE, LOGGER_LOG_TYPE, removeAccents, STORAGE } from 'Config';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Reducers } from 'store/types';
import { InsuranceContractDto, InsuranceContractTypeItemDto } from '../../../../../../api/contracts/insuranceContract/models/InsuranceContractDto';
import styles from './InsuranceContractsScreen.module.scss';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { ReactComponent as TrashIcon } from 'assets/svg/trash_no_color.svg';
import { AlertNoticeDto, SelectedAlertNoticeDto } from 'api/alertNotices/models/AlertNoticeDto';
import Storage from 'common/services/Storage';

export type Props = {
    itemId?: string;
    startDateEdit?: Date;
    endDateEdit?: Date;
    isDetails: boolean;
    editable: boolean;
    onBackList: () => void;
    onItemDetails: (state: boolean) => void;
    onSubmitted: () => void;
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const InsuranceContractsScreen = ({ itemId, editable, startDateEdit, endDateEdit, isDetails, onBackList, onItemDetails, onSubmitted, ...props }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { vehicleId } = useParams<{ vehicleId: string }>();
    const form = useForm<InsuranceContractDto>({ shouldUnregister: false });
    const { handleSubmit, getValues, setValue, control, errors, watch, reset } = form;
    const locale = Storage.get(STORAGE.CURRENT_LOCALE) || DEFAULT_LANGUAGE;
    const [itemToRemove, setItemToRemove] = useState<InsuranceContractDto>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [insuranceContract, setInsuranceContract] = useState<InsuranceContractDto | null>(null);

    const [useContractOptions, setUseContractOptions] = useState<SelectValueLabel[]>([]);
    const [insuranceContractTypeOptions, setInsuranceContractTypeOptions] = useState<SelectValueLabel[]>([]);
    const [supplierOptions, setSupplierOptions] = useState<SelectValueLabel[]>([]);

    const [selectPeriodicity, setSelectedPeriodicity] = useState<string | null>(null);

    const periodicityOptions: SelectValueLabel[] = Object.keys(Periodicity).map(key => ({
        value: key,
        label: t('common.periodicities.' + key as any)
    }));

    const [includeContract, setIncludeContract] = useState<boolean>(false);

    const [savedFiles, setSavedFiles] = useState<CustomFile[]>([]);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasContractsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_CONTRACTS_WRITE']);

    const [alertNoticeOptions, setAlertNoticeOptions] = useState<SelectValueLabel[]>([]);
    const [showAlertNotice, setShowAlertNotice] = useState<boolean>(false);
    const [showRemoveAlertNotice, setShowRemoveAlertNotice] = useState<boolean>(false);
    const [alertNoticeToRemoveIndex, setAlertNoticeToRemoveIndex] = useState<number | null>(null);
    const [alertNoticeIdsToRemove, setAlertNoticeIdsToRemove] = useState<string[]>([]);

    const alertNotices = useFieldArray<SelectedAlertNoticeDto>({ control: form.control, name: 'alertNotices', keyName: 'formId' as any });


    const getData = async () => {
        setValue('attachmentsToRemove', []);
        try {

            Loading.show();

            const useContracts = await UseContractsService.getUseContractsByVehicle(vehicleId, true);
            setUseContractOptions(useContracts);
            
            const insuranceContractTypesOrig =  await InsuranceContractsService.getInsuranceContractTypes();
            const insuranceContractTypes = insuranceContractTypesOrig.map(x => ({ ...x, label: t(`common.insurance_contract_types.${x.label}` as any) }));
            const insuranceContractTypesOrdered = Utils.sortAlphabetically(insuranceContractTypes);
            setInsuranceContractTypeOptions(insuranceContractTypesOrdered);


            const suppliers = await SuppliersService.catalog({ typeName: GenericType.INSURANCE } as CatalogSupplierSearchCriteria);
            setSupplierOptions(Utils.sortAlphabetically((suppliers || []).map((x: SelectValueLabel) => ({ value: x.value || '', label: x.label || '' }))));

            const alertNoticesDB: AlertNoticeDto[] = await AlertNoticesService.getAll();
            buildAndSetAlertNoticeOptions(alertNoticesDB);

            if (itemId) {
                const result = await InsuranceContractsService.getById(itemId);

                if (result === undefined) {
                    addToast(t('common.messages.error_load_info'), { appearance: 'error' });
                    Logger.error(LOGGER_LOG_TYPE.INSURANCE_CONTRACTS, `Couldn't get the insurance contract with id: ${itemId}`);
                    onBackList();
                }

                result.startDate = moment(result.startDate).toDate();
                result.endDate = moment(result.endDate).toDate();

                reset(result);

                setIncludeContract(result.includeUseContractId ? true : false);

                setValuesFiles(result.attachments);

                setInsuranceContract(result);
            }

            setShowAlertNotice(true);

            Loading.hide();

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.INSURANCE_CONTRACTS, `Couldn't get information to create insurance contract`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const getUseContractAssociate = async (id: string) => {
        try {
            const result = await UseContractsService.getById(id);
            result.startDate = moment(result.startDate).toDate();
            result.endDate = result.endDate != undefined ? moment(result.endDate).toDate() : undefined;

            reset({ ...insuranceContract, includeUseContractId: id, cost: 0, periodicity: result.periodicity, startDate: moment(result.startDate).toDate(), endDate: result.endDate != undefined ? moment(result.endDate).toDate() : undefined, supplierId: supplierOptions.find(x => x.value == result.supplierId) ? result.supplierId : undefined });
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.INSURANCE_CONTRACTS, `Couldn't get information to use contract associate`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const buildAndSetAlertNoticeOptions = (alertNoticesArr: AlertNoticeDto[]) => {
        let mappedAlertNotices: SelectValueLabel[] = [];
        if (alertNoticesArr) {
            mappedAlertNotices = alertNoticesArr.filter(item => item.alertNoticeType == AlertNoticeTypeEnum.TIME_PERIODS).map((x, i) => {
                const label: string = x.value + ' ' + t(('maintenances.periodicities.' + x.periodicity) as any) + ' ' + t(('maintenances.alert_notice_comparison.' + x.alertNoticeComparison) as any);
                return { label, value: x.id, fullLabel: x.alertNoticeType }
            });
        }
        setAlertNoticeOptions(mappedAlertNotices);
    }

    const onSubmit = async (form: InsuranceContractDto) => {
        try {
            form.vehicleId = vehicleId;

            if (form.number == null || form.cost == null || (!form.periodicity && !includeContract) || !form.startDate || !form.endDate) {
                addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                return;
            }

            Loading.show();
            setShowAlertNotice(false);
            prepareFilesToSubmit();

            if (form.id) {
                await InsuranceContractsService.update(form, form.attachments ?? [])
            } else {
                itemId = await InsuranceContractsService.create(form, form.attachments ?? [])
            }

            getData();
            Loading.hide();
            onSubmitted();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
        } catch (error) {
            Loading.hide();

            if (error?.response?.status === 409) {
                addToast(t('common.messages.name_already_exists'), { appearance: 'warning' });
                return;
            }

            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.INSURANCE_CONTRACTS, `Couldn't update the insurance contract with id: ${form.id}`, error);
            arrangeFilesAfterSubmit();

        }
    }

    const showRemoveItemDialog = (item: InsuranceContractDto) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await InsuranceContractsService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                onBackList();
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    const arrangeFilesAfterSubmit = () => {
        setValuesFiles(savedFiles);
    }

    const prepareFilesToSubmit = () => {
        setValuesFiles(getValues('attachments')?.filter(x => !x.id));
    }

    const setValuesFiles = (files: CustomFile[]) => {
        setValue('attachments', files);
        setSavedFiles(files?.filter(x => x.id));
    }

    const onRemoveFile = (file: CustomFile) => {
        if (file.id) {
            const arr = [...(getValues('attachmentsToRemove') || []), file.id];
            setValue('attachmentsToRemove', arr);
        }
    }

    const addAlertNotice = () => {
        alertNotices.append({
            id: null
        });
    }

    const renderAlertNotice = (index: number, an: any) => {
        return (
            <SelectController
                form={form}
                name={`alertNotices[${index}].id`}
                menuPortalTarget={document.querySelector('body')}
                options={alertNoticeOptions}
                placeholder={t('maintenances.alert')}
                rules={{ required: true }}
                isDisabled={(isDetails)}
                filterOption={(itemFilter: any, input: any) => input ? removeAccents(itemFilter.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
            />);
    }

    const removeAlertNotice = () => {
        if (alertNoticeToRemoveIndex === null) return;

        if (alertNotices.fields[alertNoticeToRemoveIndex]?.id) {
            setAlertNoticeIdsToRemove([...alertNoticeIdsToRemove, alertNotices.fields[alertNoticeToRemoveIndex].id!]);
        }

        alertNotices.remove(alertNoticeToRemoveIndex);

        setShowRemoveAlertNotice(false);
    }

    const setRemoveAlertNotice = (index: number) => {
        setShowRemoveAlertNotice(true);
        setAlertNoticeToRemoveIndex(index);
    }

    const renderButtons = () => {
        return <div className={styles.buttonsContainer}>
            <Button
                preset={'secondary'}
                type="button"
                onClick={() => {
                    if (insuranceContract) reset(insuranceContract);
                    onBackList();
                }}
                text={t('common.cancel')}
            />
            {isDetails && hasContractsWritePolicy && editable &&
                <Button
                    type="button"
                    text={t('common.remove')}
                    preset={'danger'}
                    onClick={() => showRemoveItemDialog({ id: insuranceContract?.id } as InsuranceContractDto)} />
            }
            {isDetails && editable &&hasContractsWritePolicy && <Button
                type={'button'}
                text={t('common.edit')}
                onClick={() => onItemDetails(false)}
            />}
            {!isDetails && hasContractsWritePolicy && <Button
                type={'submit'}
                text={t('common.save')}
            />}

            <QuestionYesNo message={t('common.messages.remove_record')}
                isVisible={dialogDeleteItemIsOpen}
                onYes={() => removeItem()}
                onNo={() => setDialogDeleteItemIsOpen(false)} />
        </div>
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div {...props}>
                <Row style={{ margin: 0 }}>
                    <Col xs={12} sm={12} md={12} lg={8} xl={9}>
                        <div className={styles.content}>
                            <Row style={{ alignItems: 'end' }}>
                                <Col xs={12}>
                                    <div className={styles.header}>
                                        <img src={IconBack} className={styles.icon} onClick={() => onBackList()} /> {t('contracts.insurance_contract.title')}
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('contracts.insurance_contract.include_use_contract')}</Label>
                                        <SelectController
                                            form={form}
                                            isDisabled={isDetails}
                                            placeholder={t('contracts.insurance_contract.use_contract_number')}
                                            name="includeUseContractId"
                                            isClearable={true}
                                            options={useContractOptions}
                                            onChangeSelect={(data: SelectValueLabel) => {
                                                if (!data) {
                                                    setIncludeContract(false);
                                                    reset({ ...insuranceContract, includeUseContractId: undefined, supplierId: undefined });
                                                } else {
                                                    setIncludeContract(true);
                                                    const id = getValues('includeUseContractId')?.toString();
                                                    id && getUseContractAssociate(id);
                                                }
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('common.type')}</Label>
                                        <Controller
                                            render={({ onChange, value }) => {
                                                return (
                                                    <Select
                                                        options={insuranceContractTypeOptions}
                                                        isClearable
                                                        isMulti
                                                        isDisabled={isDetails}
                                                        placeholder={t('common.types')}
                                                        onChange={(data: SelectValueLabel[]) => {
                                                            onChange(data?.map(x => ({ insuranceContractTypeId: x.value })));
                                                        }}
                                                        value={value ? insuranceContractTypeOptions.filter(x => value.find((y: InsuranceContractTypeItemDto) => y.insuranceContractTypeId === x.value)) : []}
                                                        filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                                    />
                                                );
                                            }}
                                            control={control}
                                            name="insuranceContractTypes"
                                            defaultValue={getValues('insuranceContractTypes')} />
                                    </FormItem>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('contracts.insurance_contract.number')} {!isDetails ? '*' : ''}</Label>
                                        <InputController
                                            name="number"
                                            form={form as any}
                                            autoComplete='off'
                                            disabled={isDetails}
                                            placeholder={t('contracts.insurance_contract.number')}
                                            rules={{ ...DEFAULT_INPUT_RULES_WITH_REQUIRED }} />
                                        <InputError error={errors.number} />
                                    </FormItem>
                                </Col>
                                {!includeContract && <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('common.periodicity')} {!isDetails ? '*' : ''}</Label>
                                        <SelectController
                                            form={form}
                                            isDisabled={isDetails}
                                            placeholder={t('common.periodicity')}
                                            name="periodicity"
                                            options={periodicityOptions}
                                            rules={{ required: true }}
                                            onChangeSelect={(data: SelectValueLabel) => {
                                                setSelectedPeriodicity(data ? data.label : null);
                                            }} />
                                        <InputError error={errors.periodicity} />
                                    </FormItem>
                                </Col>}
                                {!includeContract && <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('contracts.insurance_contract.cost', { vat : (userProfile?.useValueWithVat? t('common.with_vat') : t('common.without_vat')) })} {!isDetails ? '*' : ''}</Label>
                                        <InputGroupController
                                            type="number"
                                            text={selectPeriodicity ? t('common.euro') + '/' + selectPeriodicity : t('common.euro')}
                                            placeholder={t('contracts.insurance_contract.cost', { vat : (userProfile?.useValueWithVat? t('common.with_vat') : t('common.without_vat')) })}
                                            name="cost"
                                            form={form}
                                            disabled={isDetails || includeContract}
                                            format="money"
                                            rules={{ required: true }} />
                                        <InputError error={errors.cost} />
                                        {includeContract && <span className={styles.messageAlert}>{'*' + t('contracts.insurance_contract.included_maintenance_contract_in_cost')}</span>}
                                    </FormItem>
                                </Col>}
                                <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('common.start_end_date')} {!isDetails ? '*' : ''}</Label>
                                        <div className={styles.dateRangeCustom}>
                                            <DateTimePickerRange
                                                dateFormat="dd/MM/yyyy"
                                                locale={locale}
                                                start={watch('startDate')}
                                                end={watch('endDate')}
                                                showYearDropdown
                                                key={`watch-${watch('startDate')}-${watch('endDate')}`}
                                                disabled={isDetails}
                                                placeholderText={t('common.start_end_date')}
                                                onChange={() => {
                                                    // do nothing.
                                                }}
                                                onChangeRange={(start: Date | null, end: Date | null) => {
                                                    const sd = start ? Utils.generateDate(start.getFullYear(), start.getMonth(), start.getDate()) : null;
                                                    setValue('startDate', sd);

                                                    let dateLastDayOfMonth = end;
                                                    if (end) {
                                                        dateLastDayOfMonth = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59);
                                                    }
                                                    setValue('endDate', dateLastDayOfMonth);
                                                }}
                                            />
                                        </div>
                                        <InputError error={form.formState.isSubmitted && (!watch('startDate') || !watch('endDate')) ? { type: 'dateRange', message: t('common.errors.date_not_filled') } : undefined} />
                                    </FormItem>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('contracts.insurance_contract.supplier')}</Label>
                                        <SelectController
                                            form={form}
                                            isDisabled={isDetails}
                                            placeholder={t('contracts.insurance_contract.supplier')}
                                            name="supplierId"
                                            options={!supplierOptions.find(x => x.value == getValues('supplierId')) && Boolean(itemId) ?
                                                [...supplierOptions, { label: getValues('supplierName'), value: getValues('supplierId') }] as SelectValueLabel[] : supplierOptions}
                                            value={isDetails && getValues('supplierId') ? { label: getValues('supplierName'), value: getValues('supplierId') } as SelectValueLabel : null} />
                                        <InputError error={errors.supplierId} />
                                    </FormItem>
                                </Col>

                                {<Col xs={12}>
                                    <div className={styles.containerSmallButton}>
                                        <Label className={styles.label}>{t('maintenances.alert_notices_over_date_end')}</Label>
                                        {!isDetails && <ScreenHeaderButton icon={AddIcon} onClick={addAlertNotice} classNameIcon={styles.iconAdd} />}
                                    </div>

                                    {alertNotices.fields.length === 0 && <div className={styles.noInfoText}>
                                        {t('maintenances.without_alert_notices')}
                                    </div>}

                                    {!!alertNoticeOptions && !!showAlertNotice && !!alertNotices && alertNotices.fields.map((x, i) => {
                                        return (<Row key={x.formId} className={styles.rowAlerts}>
                                            <Col xs={4}>
                                                {renderAlertNotice(i, x)}
                                            </Col>
                                            {!isDetails && <Col xs={1}>
                                                <TrashIcon className={styles.removeIcon} onClick={() => setRemoveAlertNotice(i)} />
                                            </Col>}
                                        </Row>)
                                    })}
                                </Col>}

                            </Row>
                            {renderButtons()}
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4} xl={3}
                        className={styles.colRightContent}>
                        <Label className={styles.subtitle}>{t('common.attachment')}</Label>
                        <FormItem>
                            <FileSelector
                                isDetails={isDetails}
                                isMulti={true}
                                smallButtonAdd={true}
                                initialFiles={getValues('attachments')}
                                onFilesChanged={setValuesFiles}
                                onRemoveFile={onRemoveFile}
                                label={<Label className={styles.label}>{t('contracts.insurance_contract.insurance_certificate')}</Label>}
                                fileButtonSizes={{ sm: 12, lg: 6 }}
                            />
                        </FormItem>
                    </Col>
                </Row>
            </div>

            <QuestionYesNo
                message={t('common.messages.remove_record')}
                isVisible={showRemoveAlertNotice}
                onYes={() => removeAlertNotice()}
                onNo={() => { setShowRemoveAlertNotice(false); setAlertNoticeToRemoveIndex(null) }}
            />

        </form>
    );
}

export default InsuranceContractsScreen;
